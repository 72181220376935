import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'; //样式文件一定要引入
import '@/assets/css/main.css'
import axios from 'axios'
import ajax from './assets/js/request.js'
import '@/utils/rem.js'
import '@/utils/mixin.js'
import {BaseUrl} from '@/config/index.js'
import VueClipboard from 'vue-clipboard2'
 



Vue.config.productionTip = false
Vue.prototype.$ajax = axios
Vue.prototype.imgUrl = 'https://www.xcrck.com'
Vue.use(ElementUI)
Vue.use(ajax)
Vue.use(VueClipboard)
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面meta */
  if(to.meta.content){
    let head = document.getElementsByTagName('head');
    let meta = document.createElement('meta');
    meta.content = to.meta.content;
    head[0].appendChild(meta)
  }
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next()
});
// 防连点
Vue.directive('preventReClick', {
  inserted(el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        setTimeout(() => {
          el.disabled = false
        }, binding.value || 2000)
      }
    })
  }
});
// 请求拦截
axios.interceptors.request.use(config => {
	console.log('config==', config)
	return config
}, error => {
	console.log('error11==', error)
	return Promise.reject(error)
})
// 响应拦截
axios.interceptors.response.use(response => {
	console.log('response==', response)
	return response
}, (error) => {
	console.log('error==', error.response.data.code)
	if (error.response.data.code == 401) {
		ElementUI.Message.error(error.response.data.msg);
		setTimeout(() => {
			router.push('/')
		}, 1000)
	}
})

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
