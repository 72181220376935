<template>
  <div id="app">
    <div class="">
      <div class="text-theme "></div>
    </div>
    <router-view/>
  </div>
</template>
<script>
	export default{
		data(){
			return{
				
			}
		},
		mounted() {
			this.$store.commit('initApp')
		},
		methods:{
			
		}
	}
</script>
<style lang="scss">
@import "./style/main.scss";
#app {
  
}

</style>
