import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'Home',
		component: () => import('../views/tab/home/home'),
		meta: {
			requireAuth: false,
			title:'乡村人才库'
		}
	},
	{
		path: '/noAuth',
		name: 'noAuth',
		component: () => import('../views/noAuth'),
		meta: {
			requireAuth: false //跳转拦截器 为 true 表示需要登录
		}
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/login/login'),
		meta: {
			requireAuth: false ,//跳转拦截器 为 true 表示需要登录
			title:'登录'
		}
	},
	{
		path: '/register',
		name: 'register',
		component: () => import('../views/login/register'),
		meta: {
			title:'注册',
			requireAuth: false
		}
	},
	{
		path: '/forget_password',
		name: 'forget_password',
		component: () => import('../views/login/forget_password'),
		meta: {
			title:'忘记密码',
			requireAuth: false
		}
	},
	{
		path: '/bind_phone',
		name: 'bind_phone',
		component: () => import('../views/login/bind_phone'),
		meta: {
			title:'绑定手机号',
			requireAuth: false
		}
	},
	{
		path: '/authen',
		name: 'authen',
		component: () => import('../views/login/authen'),
		meta: {
			title:'作家认证',
			requireAuth: true
		}
	},
	{
		path: '/workUpload',
		name: 'workUpload',
		component: () => import('../views/login/workUpload'),
		meta: {
			requireAuth: true,
			title:'作品上传',
		}
	},
	{
		path: '/memberDate',
		name: 'memberDate',
		component: () => import('../views/login/memberDate'),
		meta: {
			requireAuth: true,
			title:'会员周期',
		}
	},{
		path: '/payMoney',
		name: 'payMoney',
		component: () => import('../views/login/payMoney'),
		meta: {
			requireAuth: true,
			title:'支付',
		}
	},
	{
		path: '/my',
		name: 'my',
		component: () => import('../views/tab/my/my'),
		meta: {
			title:'个人中心',
			requireAuth: true,
			keepAlive:true
		}
	},
	{
		path: '/writer',
		name: 'writer',
		component: () => import('../views/tab/writer/writer')
	},
	{
		path: '/wallent',
		name: 'wallent',
		component: () => import('../views/user/wallent'),
		meta: {
			requireAuth: true,
			title:""
		}
	},
	{
		path: '/editArticle',
		name: 'editArticle',
		component: () => import('../views/user/editArticle'),
		meta: {
			requireAuth: true,
			title:"发布文章"
		}
	},
	
	{
		path: '/editBook',
		name: 'editBook',
		component: () => import('../views/user/editBook'),
		meta: {
			requireAuth: true,
			title:"发布电子书"
		}
	},
	{
		path: '/addSection',
		name: 'addSection',
		component: () => import('../views/user/addSection'),
		meta: {
			requireAuth: true,
			title:"创建章节"
		}
	},
	{
		path: '/publishDynac',
		name: 'publishDynac',
		component: () => import('../views/index/publishDynac'),
		meta: {
			requireAuth: true,
			title:"发布动态"
		}
	},
	{
		path: '/artileDetail',
		name: 'artileDetail',
		component: () => import('../views/index/artileDetail'),
		meta: {
			requireAuth: false,
			title:'文章详情'
		}
	},{
		path: '/worksDetail',
		name: 'worksDetail',
		component: () => import('../views/index/worksDetail'),
		meta: {
			requireAuth: true,
			title:"电子书详情"
		}
	},
	{
		path: '/transpond',
		name: 'transpond',
		component: () => import('../views/index/transpond'),
		meta: {
			requireAuth: true,
			title:"转发"
		}
	},
	{
		path: '/fictionDetail',
		name: 'fictionDetail',
		component: () => import('../views/fiction/fictionDetail'),
		meta: {
			requireAuth: true,
			title:"电子书详情"
		}
	},
	{
		path: '/complain',
		name: 'complain',
		component: () => import('../views/fiction/complain'),
		meta: {
			requireAuth: true,
			title:"版权申诉"
		}
	},
	{
		path: '/report',
		name: 'report',
		component: () => import('../views/fiction/report'),
		meta: {
			requireAuth: true,
			title:"投诉举报"
		}
	},
	{
		path: '/catalog',
		name: 'catalog',
		component: () => import('../views/fiction/catalog'),
		meta: {
			requireAuth: false,
			title:"目录列表"
		}
	},
	{
		path: '/adetail',
		name: 'adetail',
		component: () => import('../views/fiction/adetail'),
		meta: {
			requireAuth: true,
			title:"电子书详情"
		}
	},
	{
		path: '/system-msg',
		name: 'system-msg',
		component: () => import('../views/user/system-msg'),
		meta: {
			requireAuth: true,
			title:"系统通知"
		}
	},
	{
		path: '/rank-list',
		name: 'rank-list',
		component: () => import('../views/ranklist/index'),
		meta: {
			requireAuth: true,
			title:"热榜"
		}
	},
	{
		path: '/spell-book',
		name: 'spell-book',
		component: () => import('../views/spellBook/index'),
		meta: {
			requireAuth: true,
			title:"拼书"
		}
	},
	{
		path: '/books-detail',
		name: 'books-detail',
		component: () => import('../views/spellBook/booksDetail'),
		meta: {
			requireAuth: true,
			title:"拼书详情"
		}
	},
	{
		path: '/confirm-order',
		name: 'confirm-order',
		component: () => import('../views/order/confirmOrder'),
		meta: {
			requireAuth: true,
			title:"确认订单"
		}
	},
	{
		path: '/pay-success',
		name: 'pay-success',
		component: () => import('../views/order/paySuccess'),
		meta: {
			requireAuth: true,
			title:"拼单成功"
		}
	},
	{
		path: '/order-list',
		name: 'order-list',
		component: () => import('../views/order/orderList'),
		meta: {
			requireAuth: true,
			title:"拼书订单"
		}
	},
	{
		path: '/order-detail',
		name: 'order-detail',
		component: () => import('../views/order/orderDetail'),
		meta: {
			requireAuth: true,
			title:"订单详情"
		}
	},
	{
		path: '/express-list',
		name: 'express-list',
		component: () => import('../views/expressInquiry/expressList'),
		meta: {
			requireAuth: true,
			title:"快递查询"
		}
	},
	{
		path: '/express-detail',
		name: 'express-detail',
		component: () => import('../views/expressInquiry/expressDetail'),
		meta: {
			requireAuth: true,
			title:"快递查询"
		}
	},
	{
		path: '/setting',
		name: 'setting',
		component: () => import('../views/user/setting/setting'),
		meta: {
			requireAuth: true,
			title:"设置"
		}
	},
	{
		path: '/tag_identity',
		name: 'tag_identity',
		component: () => import('../views/user/tag_identity'),
		meta: {
			requireAuth: true,
			title:"职业认证"
		}
	},
	{
		path: '/editAccount',
		name: 'editAccount',
		component: () => import('../views/user/setting/editAccount'),
		meta: {
			requireAuth: true,
			title:"修改个人资料"
		}
	},
	{
		path: '/feedback',
		name: 'feedback',
		component: () => import('../views/user/feedback'),
		meta: {
			requireAuth: true,
			title:"意见反馈"
		}
	},
	{
		path: '/game_center',
		name: 'game_center',
		component: () => import('../views/user/game_center'),
		meta: {
			requireAuth: false,
			title:"参赛中心"
		}
	},
	{
		path: '/my_game',
		name: 'my_game',
		component: () => import('../views/user/my_game'),
		meta: {
			requireAuth: true,
			title:"我的参赛"
		}
	},
	{
		path: '/myFocus',
		name: 'myFocus',
		component: () => import('../views/user/myFocus'),
		meta: {
			requireAuth: true,
			title:"我的关注"
		}
	},
	{
		path: '/center_detail',
		name: 'center_detail',
		component: () => import('../views/user/center_detail'),
		meta: {
			requireAuth: false,
			title:"赛事详情"
		}
	},
	{
		path: '/publish',
		name: 'publish',
		component: () => import('../views/user/publish'),
		meta: {
			requireAuth: true,
			title:"发布"
		}
	},
	{
		path: '/about',
		name: 'about',
		component: () => import('../views/user/about'),
		meta: {
			title:"关于我们",
			requireAuth: false
		}
	},
	{
		path: '/myWallet',
		name: 'myWallet',
		component: () => import('../views/user/myWallet'),
		meta: {
			title:"我的收益",
			requireAuth: true
		}
	},
	{
		path: '/pay',
		name: 'pay',
		component: () => import('../views/fiction/pay'),
		meta: {
			title:"立即支付",
			requireAuth: true
		}
	},
	{
		path: '/talent_index',
		name: 'talent_index',
		component: () => import('../views/talents/index'),
		meta: {
			title:"人才主页",
			requireAuth: true
		}
	},
	{
		path: '/talents_detail',
		name: 'talents_detail',
		component: () => import('../views/talents/talents_detail'),
		meta: {
			title:"人才详情",
			requireAuth: true
		}
	},
	{
		path: '/author_intro',
		name: 'author_intro',
		component: () => import('../views/talents/author_intro'),
		meta: {
			title:"作者简介",
			requireAuth: true
		}
	},
	{
		path: '/pushWorks',
		name: 'pushWorks',
		component: () => import('../views/user/pushWorks'),
		meta: {
			title:"作品上传",
			requireAuth: true
		}
	}
]

const router = new VueRouter({
	routes
})
//导航前守卫(跳转前)
router.beforeEach(function(to,from,next){
	// to: 表示要跳转的页面。from:表示从哪个页面来
	let login_in = localStorage.getItem('user_token') ? true : false                  // 是否已登录
	let require = to.matched.some(function(item){      // 是否需要登录
	  return item.meta.requireAuth
	})
	if( !login_in && require ){       // 当未登录，且跳转的页面需要登录后才能操作时，进行路由拦截
	  next({                          // 跳转登录页
		name: "login",
		params: { redirect: to }      // 将 要跳转（即被拦截） 的路由对象，作为参数，传递到登录页面
	  });
	}else{                            // 已登录就正常跳转，不做任何拦截
	  next()                          // 正常跳转到下一页
	}
})
export default router
