import Vue from 'vue'
import axios from 'axios'
import qs from 'qs'
import { API_BASE_URL } from '@/config/index.js'
// import ElementUI from 'element-ui';
import store from '@/store/index';

export default {
	setFormData(data) {
		let formData = '';
		for (let p in data) {
			formData += p + '=' + data[p] + '&';
		}
		return formData.slice(0, -1);
	},
	install() {
		Vue.prototype.ajax = (type, url, option, callback) => {
			let formData = qs.stringify(option)
			return axios({
				method: type,
				url: (process.env.NODE_ENV !== 'production' && process.env.OPEN_PROXY ? '/api' :
					API_BASE_URL) + url,
				data: formData,
				responseType: 'text',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					// 'token': sessionStorage.getItem('token'),
					'user-token': localStorage.getItem('user_token') || 0
					// 'group-id': 0,
					// "lang": 'zh'
				}
			}).then(res => {
				// let that = Vue
				// console.log('res==',res)
				// if(!res){
				//   Vue.$router.push('/')
				// }
				// console.log('res.data.code',res)
				// if(res.data.code==401){
				//   this.$message.error(res.data.msg);
				// }
				if (res.data.code=='-201' || res.data.code == '-202' || res.data.code == '-203') {
					global.userInfo = null;
					global.token = null;
					localStorage.removeItem('USER_INFO');
					localStorage.removeItem('user_token');
					callback && callback(res.data);
				}else{
					callback && callback(res.data);
				}

			}, err => {
				console.log('err====', err)
			})
		}
	}
}
