import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		is_dev: 0,
		userInfo: null // 用户信息
	},
	mutations: {
		// 初始化APP,获取缓存的token,用户信息等其他缓存内容
		initApp (state) {
			const _userInfo = JSON.parse(localStorage.getItem('USER_INFO'));
			if (_userInfo) {
				state.userInfo = _userInfo;
				global.userInfo = _userInfo;
				global.token = _userInfo.user_token;
			}
		},
		// 退出登录
		logout (state) {
			state.userInfo = null;
			global.userInfo = null;
			global.token = null;
			// this.getters.isLogin = false;
			console.log('删除方法', localStorage.getItem('USER_INFO'));
			localStorage.removeItem('USER_INFO');
			localStorage.removeItem('user_token');
		},
		// 更新用户信息
		updateUserInfo (state, data) {
			if (state.userInfo) {
				state.userInfo = {
					...state.userInfo,
					...data,
				};
			} else {
				state.userInfo = data;
			}
			localStorage.getItem('USER_INFO', state.userInfo);
			global.userInfo = state.userInfo;
			if (state.userInfo.user_token) {
				global.token = state.userInfo.user_token;
			}
		}
	},
	getters: {
		isLogin (state) {
			if (state.userInfo == null) {
				return false;
			} else {
				return true;
			}
		},
		// 是否作家
		is_writer (state) {
			// let userInfo =  JSON.parse(state.userInfo)
			if (state.userInfo && state.userInfo.is_writer == 1) {
				return true;
			} else {
				return false;
			}
		},
		// 用户信息
		userInfo (state) {
			if (state.userInfo) {
				return state.userInfo;
			} else {
				return null;
			}
		}
	},
	actions: {
	},
	modules: {
	}
})
