import Vue from 'vue'
// 全局混入
Vue.mixin({
	data() {
		return {
			
		}
	},
	mounted() {
		
	},
	methods:{
		/* 新标签页跳转页面 */
		toBlank(obj){
			if(localStorage.getItem('user_token')){
				let routeData = this.$router.resolve(obj);
				window.open(routeData.href, '_blank');
			}else{
				this.$router.push('/login')
			}
			
		}
		
	}
})